import React, { useEffect, useState } from "react";
import { Provider as JotaiProvider, useAtom, useSetAtom } from "jotai";
import { Container } from "components/Container";
import {Botones} from "./componentes/botones";

const ListarHorariosPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Bandeja de Horarios" botonExtra={<Botones/>}>
           {/* <TablaVacaciones/> */}
           {/*<ModalRechazarSolicitudVacaciones/> */}
        </Container>
    </JotaiProvider>
  )
}

export default ListarHorariosPage;