import { atom } from "jotai";

export const colaboradorAtom = atom(undefined);
export const visibleModalActivarColaboradorAtom = atom(false);
export const visibleModalExitoAtom = atom(false);
export const visibleModalVisorPdfAtom = atom(false);
export const urlPdfAtom = atom(undefined);
//export const nombreDocumentoAtom = atom(undefined);
export const documentoSeleccionadoAtom = atom(undefined);
export const onboardingsIngresoAtom = atom([]);

//tab: contratos
export const contratosAtom = atom([]);
export const visibleModalCargarDocumentoAtom = atom(false);
export const visibleModalTransferirProyectoAtom = atom(false);

//tab: Onboarding
export const enviosAtom = atom([]);
export const envioSeleccionadoAtom = atom(undefined);
export const eventoAtom = atom(undefined);
export const permisosDocumentosAtom = atom([]);
export const opcionesAtom = atom([]);

//tab: documentos
export const documentosAtom = atom(undefined);
export const carpetaSeleccionadaAtom = atom(undefined);
export const openModalSubirDocumentosAtom = atom(false);
export const openModalVisorPdfAtom = atom(false);
export const loadingDescargaAtom = atom(false);

export const openPopAtom = atom(false);