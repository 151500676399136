import { BaseService } from "./BaseService";
import qs from "qs";

const ColaboradorService = {
	async listar(empresaId) {
		const respuesta = await BaseService.get("/colaborador", {
			params: {
				empresaId: empresaId,
			},
		});
		return respuesta.data;
	},
	async insertar(colaborador) {
		const respuesta = await BaseService.post("/colaborador", colaborador);
		return respuesta.data;
	},
	async actualizar(colaborador) {
		const respuesta = await BaseService.put("/colaborador", colaborador);
		return respuesta.data;
	},
	async eliminar(id) {
		const respuesta = await BaseService.delete(`/colaborador/${id}`);
		return respuesta.data;
	},
	async buscarPorId(id) {
		const respuesta = await BaseService.get(`/colaborador/${id}`);
		return respuesta.data;
	},
	async buscarPorNumeroDocumentoIdentidad(empresaId, numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("/colaborador/buscarPorNumeroDocumentoIdentidad", {
			params: {
				empresaId: empresaId,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
			},
		});
		return respuesta.data;
	},
	async cargarMasivamente(formData) {
		const respuesta = await BaseService.post("/colaborador/cargarMasivamente", formData);
		return respuesta.data;
	},
	async quitarAccesoPlataforma(id) {
		const respuesta = await BaseService.post(`/colaborador/quitarAccesoPlataforma/${id}`);

		return respuesta.data;
	},
	async habilitarAccesoPlataforma(id) {
		const respuesta = await BaseService.post(`/colaborador/habilitarAccesoPlataforma/${id}`);

		return respuesta.data;
	},
	async activarColaborador(id) {
		const respuesta = await BaseService.post(`/colaborador/activar/${id}`);

		return respuesta.data;
	},
	async eliminarColaborador(id) {
		const respuesta = await BaseService.post(`/colaborador/eliminar/${id}`);

		return respuesta.data;
	},
	async desactivarColaborador(id) {
		const respuesta = await BaseService.post(`/colaborador/cesar/${id}`);

		return respuesta.data;
	},
	async eliminarTodos(organizacionId, empresaId) {
		const respuesta = await BaseService.get("/colaborador/eliminarTodos", {
			params: {
				organizacionId: organizacionId,
				empresaId: empresaId,
			},
		});
		return respuesta.data;
	},

	async descargarExcel(
		empresaId,
		accesoPlataforma,
		estado,
		tipoDocumento,
		colaborador,
		documentoIdentidad,
		codigoColaborador,
		centroCosto,
		fechaIngreso,
		login,
		mostrarSueldo
	) {
		const respuesta = await BaseService.get(
			"/colaborador/descargarExcel",

			{
				responseType: "blob",
				params: {
					empresaId: empresaId,
					accesoPlataforma: accesoPlataforma,
					estado: estado,
					tipoDocumento: tipoDocumento,
					colaborador: colaborador,
					documentoIdentidad: documentoIdentidad,
					codigoColaborador: codigoColaborador,
					centroCosto: centroCosto,
					fechaIngreso: fechaIngreso,
					login: login,
					mostrarSueldo: mostrarSueldo
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			}
		);
		return respuesta;
	},
	async descargarPlantillaExcel(empresaId) {
		const respuesta = await BaseService.get("/colaborador/descargarPlantillaExcel", {
			responseType: "blob",
			params: {
				empresaId: empresaId,
			},
		});
		return respuesta;
	},
	async listarPorNombreCompletoODni(data) {
		const respuesta = await BaseService.get("/colaborador/listar-por-nombre-o-dni", {
			params: {
				...data,
			},
		});
		return respuesta.data;
	},
	async listarColaboradoresPorFiltrosUsuario(empresaId, usuarioId) {
		const respuesta = await BaseService.get("/colaborador/listar-por-filtro-usuario", {
			params: {
				empresaId: empresaId,
				usuarioId: usuarioId,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},

	async descargarPlantillaVerificacionDatos() {
		const respuesta = await BaseService.get("/colaborador/descargarExcelValidacionDatos", {
			responseType: "blob",
		});
		return respuesta;
	},

	async notificarVerificacionDatosColaborador(colaboradores) {
		const respuesta = await BaseService.post("/colaborador/cargarExcelVerificacionDatos", colaboradores);
		return respuesta.data;
	},

	async verificarCorreoElectronico(empresaId, numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("/colaborador/verificarCorreoElectronico", {
			params: {
				empresaId: empresaId,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
			},
		});
		return respuesta.data;
	},

	async verificarCelular(empresaId, numeroDocumentoIdentidad) {
		const respuesta = await BaseService.get("/colaborador/verificarCelular", {
			params: {
				empresaId: empresaId,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
			},
		});
		return respuesta.data;
	},
	async descargarPlantillaSeleccion() {
		const respuesta = await BaseService.get("/colaborador/descargarPlantillaSeleccion", {
			responseType: "blob",
		});
		return respuesta;
	},

	async listarPaginado(
		vinculoLaboral,
		empresaId,
		accesoPlataforma,
		estado,
		tipoDocumento,
		colaborador,
		documentoIdentidad,
		codigoColaborador,
		centroCosto,
		proyecto,
		reclutador,
		sede,
		cargo,
		perfil,
		subsede,
		seccion,
		planilla,
		fechaIngreso,
		login,
		pagina
	) {
		const respuesta = await BaseService.get("/colaboradorPaginado", {
			params: {
				vinculoLaboral: vinculoLaboral,
				empresaId: empresaId,
				accesoPlataforma: accesoPlataforma,
				estado: estado,
				tipoDocumento: tipoDocumento,
				colaborador: colaborador,
				documentoIdentidad: documentoIdentidad,
				codigoColaborador: codigoColaborador,
				centroCosto: centroCosto,
				proyecto: proyecto,
				reclutador: reclutador,
				fechaIngreso: fechaIngreso,
				sede: sede,
				cargo: cargo,
				perfil: perfil,
				subsede: subsede,
				seccion: seccion,
				planilla: planilla,
				login: login,
				pagina: pagina,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},

	async camposColaborador(empresaId, documentoIdentidad) {
		const respuesta = await BaseService.get("/colaboradorCampos", {
			params: {
				empresaId: empresaId,
				documentoIdentidad: documentoIdentidad,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},

	async camposColaboradorSeleccionado(empresaId, campoId, campo) {
		const respuesta = await BaseService.get("/colaboradorCamposSeleccionado", {
			params: {
				empresaId: empresaId,
				campoId: campoId,
				campo: campo,
				//documentoIdentidad: documentoIdentidad
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},
	async camposColaboradorListarNuevo(empresaId) {
		const respuesta = await BaseService.get("/colaboradorCamposListarNuevo", {
			params: {
				empresaId: empresaId,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},

	async listarTodos(login) {
		const respuesta = await BaseService.get("/colaborador/listar", {
			params: {
				login: login,
			},
		});
		return respuesta.data;
	},
	async notificarMensajeBienvenida() {
		const respuesta = await BaseService.get("/colaborador/notificar_mensaje_bienvenida");
		return respuesta.data;
	},
	async procesarMigracion() {
		const respuesta = await BaseService.get("/contrato/procesarMigracion");
		return respuesta.data;
	},
	async reingresarColaborador(colaborador) {
		const respuesta = await BaseService.post("/colaborador/reingresar", colaborador);
		return respuesta.data;
	},

	async procesarImagenesColaborador(id, accion, tipo) {
		const respuesta = await BaseService.post(`/colaborador/procesarImagenes/${id}/${accion}/${tipo}`);

		return respuesta.data;
	},

	async actualizarFiltros(colaborador) {
		const respuesta = await BaseService.post("/colaborador/actualizar/filtros", colaborador);
		return respuesta.data;
	},
};

export default ColaboradorService;
