import React, {useState, useEffect} from "react";
import { Form, Input, Row, Col, Button, TimePicker, Select, Space, Table, notification } from "antd";
import { PlusOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { TipoTurno } from "enums/asistencia/TipoTurno";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { TurnoService } from "services/asistencias/TurnoService";
import moment from "moment";

const FormCrearHorario = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const diasSemana = {
    "1": "Lunes",
    "2": "Martes",
    "3": "Miércoles",
    "4": "Jueves",
    "5": "Viernes",
    "6": "Sábado",
    "7": "Domingo"
  };

  const [options, setOptions] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [turnosSeleccionado, setTurnosSeleccionado] = useState([]);
  useEffect(() => {
    const CargarDatos = async () => {
      const turnos = await TurnoService.listar();
      console.log("turnos horario:::", turnos);
      setTurnos(turnos);

      let options = turnos.map(turno => ({
        value: turno.id,
        label: `${turno.nombre}`,
      }));
      setOptions(options);
    };
    CargarDatos();
  }, []);

  const handleChange = (value) => {
    //console.log(`selected ${value}`);
    console.log("value::", value);
    console.log("turnos::", turnos);
    const selectedTurnos = turnos.filter(turno => value.includes(turno.id));
    setTurnosSeleccionado(selectedTurnos);
    console.log('Turnos seleccionados:', selectedTurnos);
  };

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center"
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      align: "center"
    },
    {
      title: "Días",
      align: "center",
      render: (text, fila) => {
        // Convierte los valores numéricos en los nombres de los días
        const dias = fila.dias.map(dia => diasSemana[dia]).join(', ');
        return dias;
      },
    },
    {
      title: "Hora Inicio",
      align: "center",
      dataIndex: "horaInicio",
    },
    {
      title: "Hora Fin",
      align: "center",
      dataIndex: "horaFin",
    },
  ];

  const handleOnClickCancelar = () => {
    history.push("/horarios");
  };

  const onClickBtnCrearHorario= async(form) =>{
    try{
      setLoading(true);
      

      form.horaInicio = moment(form.horaInicioAuxiliar).format('HH:mm');
      form.horaFin = moment(form.horaFinAuxiliar).format('HH:mm');

      console.log("turnos", form);
      const respuesta = await TurnoService.insertar(form);     
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el turno exitosamente",
      });
       setLoading(false);
      history.push(`/asistencia/turnos`);
    } catch (error) {
       setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al registrar el turno",
      });
    }
  }

  return (
      <Form
            layout="vertical"           
            //size="middle"
            onFinish={onClickBtnCrearHorario}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
                  label="Nombre"
                  name="nombre"
                  rules={[
                      {
                          required: true,
                          message: "El campo nombre es obligatorio.",
                      },
                  ]}   
              >
                  <Input/>
              </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                  label="Descripción"
                  name="descripcion"
                  rules={[
                      {
                          required: true,
                          message: "El campo descripción es obligatorio.",
                      },
                  ]}   
              >
                  <Input/>
              </Form.Item>
          </Col>
         </Row> 
         <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                    label="Turnos"
                    name="turnos"
                    rules={[
                      {
                            required: true,
                            message: "El campo días es obligatorio.",
                        },
                    ]}   
                >
                  <Select
                      placeholder="Seleccione el turno"
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      onChange={handleChange}
                      tokenSeparators={[',']}
                      options={options}
                    />
                </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
          <Col span={24}>
            <Table
                rowKey={(turnosSeleccionado) => turnosSeleccionado.id}
                dataSource={turnosSeleccionado}
                columns={columnas}
              />
          </Col>
         </Row>
         <Row justify="center" style={{ paddingTop: "15px" }}>
            <Space>
              <Col>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="default" onClick={handleOnClickCancelar}>
                    Cancelar
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                    Crear Horario
                  </Button>
                </Form.Item>
              </Col>
            </Space>
          </Row>
      </Form>
  )
};
export default FormCrearHorario;
