import React, { useState, useContext, useEffect } from "react";

import {
    FolderFilled,
    FileTextFilled,
    HomeOutlined,
    FolderOpenOutlined,
    MoreOutlined,
    Loading3QuartersOutlined,
    DeleteOutlined,
    CloudDownloadOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons"

import {
    carpetaSeleccionadaAtom,
    colaboradorAtom,
    documentoSeleccionadoAtom,
    loadingDescargaAtom,
    openModalSubirDocumentosAtom,
    openModalVisorPdfAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";

import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { Alert, Breadcrumb, Button, Divider, Dropdown, Modal, Row, Space, Table, message } from "antd";
import { TipoDocumentoLegajo } from "enums/TipoDocumentoLegajo";
import DetalleEnvioService from "services/DetalleEnvioService";
import ModalSubirDocumentos from "./ModalSubirDocumentos";
import { ModalVisorPdf } from "components/common";
import { useListarDocumentos } from "../hooks";
import { saveAs } from "file-saver";
import produce from "immer";
import { DescargaMasivaService, LegajoColaboradorService } from "services";
import { LoginService } from "services/LoginService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { usePermisos } from "hooks";

const isLaredo = LoginService.getUsuarioAutenticado()?.empresaSeleccionada?.ruc === "20132377783" ? true : false;

const LegajoTab = () => {
    const [itemsNavegacion, setItemsNavegacion] = useState([]);

    return (
        <>
            <Acciones />
            <Navegacion
                itemsNavegacion={itemsNavegacion}
                setItemsNavegacion={setItemsNavegacion} />
            <ListaDocumentos setItemsNavegacion={setItemsNavegacion} />
            <ModalSubirDocumentos />
            <ModalPdf />
        </>
    );
};

const ListaDocumentos = ({ setItemsNavegacion }) => {

    const { documentos } = useListarDocumentos(true);

    const columnaDocumento = [
        {
            title: "Nombre",
            width: "40%",
            render:
                (documento) =>
                    <TagNombreDocumento
                        documento={documento}
                        // setDocumentos={setDocumentos}
                        setItemsNavegacion={setItemsNavegacion} />
        },
        {
            title: "",
            align: "center",
            width: "5%",
            render:
                (documento) =>
                    <MenuAcciones documento={documento} />,
        },
    ];

    return (
        <Table
            rowKey={(documento) => (documento.detalleEnvioId ? documento.detalleEnvioId : documento.carpetaId)}
            bordered={true}
            dataSource={documentos}
            columns={columnaDocumento}
            pagination={false}
            scroll={{ y: "calc(100vh - 380px)" }}
            style={{
                marginTop: "10px"
            }}
        />
    );
}

const MenuAcciones = ({ documento }) => {

    const [colaborador] = useAtom(colaboradorAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [, setLoadingDescarga] = useAtom(loadingDescargaAtom);
    const [carpetaSeleccionada] = useAtom(carpetaSeleccionadaAtom);
    const { fetchDocumentos } = useListarDocumentos();
    const [loadingEliminar, setLoadingEliminar] = useState(false);

    const items = [
        {
            key: 'descargar',
            label: 'Descargar',
            icon: <CloudDownloadOutlined />
        },

    ];

    if (documento.categoriaDocumento === CategoriaDocumento.CARGA_MANUAL.nombre) {
        items.push(
            {
                key: 'eliminar',
                label: 'Eliminar',
                icon: <DeleteOutlined />,
                danger: true,
            }
        )
    }

    const onClick = ({ key }) => {
        if (key === "descargar") {
            descargar();
        } else if (key === "eliminar") {
            eliminar();
        }
    };

    const descargar = () => {
        if (documento.tipo === "CARPETA") {
            descargarCarpeta();
        } else {
            descargarArchivo();
        }
    }

    const descargarCarpeta = async () => {
        setLoadingDescarga(true);
        const response = await DescargaMasivaService.descargarCarpeta({
            numeroDocumentoIdentidad: colaborador.numeroDocumentoIdentidad,
            carpetaId: documento.carpetaId,
            login: getUsuarioAutenticado().login,
            fileName: getFileName(colaborador, "zip", " - " + documento.nombre)
        })
        saveAs(response.urlDescarga);
        setLoadingDescarga(false);
    }

    const descargarArchivo = async () => {
        setLoadingDescarga(true);
        const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
            getUsuarioAutenticado().empresaSeleccionada.id,
            documento.detalleEnvioId
        );
        saveAs(respuesta.urlDocumento, getFileName(colaborador, "pdf", ` - ${documento.periodo + " - " + documento.nombre}`));
        setLoadingDescarga(false);
    }

    const eliminar = () => {
        Modal.confirm({
            title: `¿Está seguro de eliminar el documento: ${documento.nombre} ?`,
            icon: <ExclamationCircleOutlined />,
            content: 'Al realizar esta acción el documento no podrá ser recuperado.',
            okText: 'Si, Eliminar!',
            okType: 'danger',
            cancelText: 'No',
            cancelButtonProps: { disabled: loadingEliminar },
            onOk: async () => {
                setLoadingEliminar(true);
                await LegajoColaboradorService.eliminarDocumento(documento.detalleEnvioId);
                fetchDocumentos(carpetaSeleccionada);
                setLoadingEliminar(false);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{ fontSize: "18px", fontWeight: "bold", color: "gray" }} />
            </Button>
        </Dropdown>
    );
}

const TagNombreDocumento = ({ documento, setItemsNavegacion }) => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [, setCarpetaSeleccionada] = useAtom(carpetaSeleccionadaAtom);
    const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const [, setOpenModalVisorPdf] = useAtom(openModalVisorPdfAtom);
    const { fetchDocumentos } = useListarDocumentos();

    const verDocumentosCarpeta = async () => {
        try {
            fetchDocumentos(documento.carpetaId);
            setCarpetaSeleccionada(documento.carpetaId);
            setItemsNavegacion(
                produce((draft) => {
                    draft.push({ id: documento.carpetaId, nombre: documento.nombre });
                })
            )
        } catch (error) {
            console.error(error);
        }
    };

    const verDocumento = async () => {
        try {
            if (documento.categoriaDocumento === "FORMULARIOS"
                && documento.estadoFirmaColaborador === "PENDIENTE") {
                message.error("El formulario seleccionado aun no ha sido completado.");
            } else {
                const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    documento.detalleEnvioId
                );
                setDocumentoSeleccionado({ ...documento, url: respuesta.urlDocumento });
                setOpenModalVisorPdf(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (documento.tipo === TipoDocumentoLegajo.CARPETA) {
        return (
            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => verDocumentosCarpeta()}>
                <span>
                    <FolderFilled style={{ fontSize: "30px", color: "#F7D358" }} />
                </span>
                <span style={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
                    {documento.nombre}
                </span>
            </div>
        )
    }

    return (
        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => verDocumento()}>
            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FileTextFilled style={{ fontSize: "30px", color: "#0080FF" }} />
            </span>
            <span style={{ display: "flex", paddingLeft: "8px", flexDirection: "column" }}>
                <span>
                    {documento.periodo}
                </span>
                <span style={{ fontWeight: "bold" }}>
                    {documento.nombre}
                </span>
            </span>
        </div>
    )
}

const Navegacion = ({ itemsNavegacion, setItemsNavegacion }) => {
    const [, setCarpetaSeleccionada] = useAtom(carpetaSeleccionadaAtom);
    const { fetchDocumentos } = useListarDocumentos();

    const onClickInicio = async () => {
        fetchDocumentos(undefined);
        setCarpetaSeleccionada(undefined);
        setItemsNavegacion([]);
    };

    const onClickItem = async (carpeta) => {
        fetchDocumentos(carpeta.id);

        setItemsNavegacion(
            produce((draft) => {
                const index = draft.findIndex(navegacion => navegacion.id === carpeta.id);
                draft.splice(index + 1, 1);
            })
        );
    };

    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <a onClick={onClickInicio}>
                    <Space>
                        <HomeOutlined style={{ fontSize: "20px" }} />
                        Inicio
                    </Space>
                </a>
            </Breadcrumb.Item>
            {itemsNavegacion.map((item) => (
                <Breadcrumb.Item key={item.id}>
                    <a onClick={() => onClickItem(item)}>
                        <Space>
                            <FolderOpenOutlined style={{ fontSize: "20px" }} />
                            <span>{item.nombre}</span>
                        </Space>
                    </a>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
}

const Acciones = () => {

    const [, setOpenModalSubirDocumentos] = useAtom(openModalSubirDocumentosAtom);
    const [colaborador] = useAtom(colaboradorAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loadingDescarga, setLoadingDescarga] = useAtom(loadingDescargaAtom);

    const { fetchOpciones, existeOpcion } = usePermisos();

    useEffect(() => {
        fetchOpciones();
    }, []);

    const onClickDescargarLegajo = async () => {
        setLoadingDescarga(true);
        const response = await DescargaMasivaService.descargarCarpeta({
            numeroDocumentoIdentidad: colaborador.numeroDocumentoIdentidad,
            login: getUsuarioAutenticado().login,
            fileName: getFileName(colaborador, "zip")
        })
        setLoadingDescarga(false);
        saveAs(response.urlDescarga);
    }

    return (
        <>
            <Row>
                <Space>
                    {existeOpcion("mantenimiento.colaborador.tab-legajo.subir-documento")
                        &&
                        <Button type="primary" onClick={() => setOpenModalSubirDocumentos(true)}>
                            Subir Documentos
                        </Button>
                    }
                    {existeOpcion("mantenimiento.colaborador.tab-legajo.descargar-legajo")
                        &&
                        <Button type="primary" onClick={() => onClickDescargarLegajo()} disabled={loadingDescarga}>
                            Descargar Legajo
                        </Button>
                    }
                </Space>
            </Row>
            {loadingDescarga &&
                <Alert
                    type="info"
                    showIcon
                    icon={<Loading3QuartersOutlined spin />}
                    message="Espere un momento mientras se descarga los documentos ..."
                    style={{ marginTop: "5px", padding: "2px 6px" }}
                />
            }
            <Divider style={{ margin: "12px", marginLeft: "0px" }} />
        </>
    )
}

const ModalPdf = () => {

    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const [open, setOpen] = useAtom(openModalVisorPdfAtom);

    return (
        <ModalVisorPdf
            titulo={documentoSeleccionado?.nombre}
            urlPdf={documentoSeleccionado?.url}
            visible={open}
            onCerrar={() => setOpen(false)} />
    )
}


const getFileName = (colaborador, formato = "pdf", extra = "") => {
    if (isLaredo) {
        return colaborador.codigoTrabajador + " - " + getNombreCompleto(colaborador) + extra + "." + formato;
    } else {
        return colaborador.numeroDocumentoIdentidad + " - " + getNombreCompleto(colaborador) + extra + "." + formato;
    }
}

const getNombreCompleto = (colaborador) => {
    return colaborador.apellidoPaterno + " " + colaborador.apellidoMaterno + " " + colaborador.nombres;
}

export default LegajoTab;
